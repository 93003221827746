
//Adventure page tutorial 
jQuery(document).ready(function() {
	
	// Step 1
	jQuery('#tutorial-button1').click(function(e) {
		e.preventDefault();
	  jQuery( ".tutorial-welcome" ).toggle( "fade");
	  jQuery( ".th-tutorial-step1" ).toggle( "fade");
	});
	
	jQuery('#tutorial-button1-end').click(function(e) {
		e.preventDefault();
	  jQuery( ".tutorial-welcome" ).toggle( "fade");
	});
	
	
	// Step 2
	jQuery('#tutorial-button2').click(function(e) {
		e.preventDefault();
	  jQuery( ".th-tutorial-step1" ).toggle( "fade");
	  jQuery( ".th-tutorial-step2" ).toggle( "fade");
	});
	
	jQuery('#tutorial-button2-end').click(function(e) {
		e.preventDefault();
	  jQuery( ".th-tutorial-step1" ).toggle( "fade");
	});
	
	
	// Step 3
	jQuery('#tutorial-button3').click(function(e) {
		e.preventDefault();
	  jQuery( ".th-tutorial-step2" ).toggle( "fade");
	  jQuery( ".th-tutorial-step3" ).toggle( "fade");
	});
	
	jQuery('#tutorial-button3-end').click(function(e) {
		e.preventDefault();
	  jQuery( ".th-tutorial-step2" ).toggle( "fade");
	});
	
	
	// Step 4
	jQuery('#tutorial-button4').click(function(e) {
		e.preventDefault();
  	  jQuery( ".th-tutorial-step3" ).toggle( "fade");
	  jQuery( ".th-tutorial-step4" ).toggle( "fade");
  	});
	
  	jQuery('#tutorial-button4-end').click(function(e) {
  		e.preventDefault();
  	  jQuery( ".th-tutorial-step3" ).toggle( "fade");
  	});
	  

	// Step 5
	jQuery('#tutorial-button5').click(function(e) {
		e.preventDefault();
	  jQuery( ".th-tutorial-step4" ).toggle( "fade");
  	});
});



//Adventure Landing Tutorial
jQuery(document).ready(function() {
	
	// Step 1
	jQuery('#tutorial-button1').click(function(e) {
		e.preventDefault();
	  jQuery( ".tutorial-landing-welcome" ).toggle( "fade");
	  jQuery( ".th-tutorial-landing-step1" ).toggle( "fade");
	});
	
	jQuery('#tutorial-button1-end').click(function(e) {
		e.preventDefault();
	  jQuery( ".tutorial-landing-welcome" ).toggle( "fade");
	});
	
	
	// Step 2
	jQuery('#tutorial-button2').click(function(e) {
		e.preventDefault();
	  jQuery( ".th-tutorial-landing-step1" ).toggle( "fade");
	  jQuery( ".th-tutorial-landing-step2" ).toggle( "fade");
	});
	
	jQuery('#tutorial-button2-end').click(function(e) {
		e.preventDefault();
	  jQuery( ".th-tutorial-landing-step1" ).toggle( "fade");
	});
	
	
	// Step 3
	jQuery('#tutorial-button3').click(function(e) {
		e.preventDefault();
	  jQuery( ".th-tutorial-landing-step2" ).toggle( "fade");
	  jQuery( ".th-tutorial-landing-step3" ).toggle( "fade");
	});
	
	jQuery('#tutorial-button3-end').click(function(e) {
		e.preventDefault();
	  jQuery( ".th-tutorial-landing-step2" ).toggle( "fade");
	});
	
	
	// Step 4
	jQuery('#tutorial-button4').click(function(e) {
		e.preventDefault();
  	  jQuery( ".th-tutorial-landing-step3" ).toggle( "fade");
	  jQuery( ".th-tutorial-landing-step4" ).toggle( "fade");
  	});
	
  	jQuery('#tutorial-button4-end').click(function(e) {
  		e.preventDefault();
  	  jQuery( ".th-tutorial-landing-step3" ).toggle( "fade");
  	});
	  

	// Step 5
	jQuery('#tutorial-button5').click(function(e) {
		e.preventDefault();
	  jQuery( ".th-tutorial-landing-step4" ).toggle( "fade");
  	});
});
