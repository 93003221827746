$(function(){

    // add custom ingredients
    $('.adventure-container .beat-card[data-node-num].recipe .recipe-ingredients button.recipe-add-custom').on('click', function(e){
        $(this).before($('<input>').attr('type', 'text').attr('maxlength', '50'));
    });

    // populate form on complete step1
    $('.adventure-container .beat-card[data-node-num].recipe .recipe-ingredients button.next').on('click', function(e){
        if ( !rebuildConfirmation($(this).closest('.recipe'), true) ) {
            e.stopImmediatePropagation();
        }
    });

    // if a confirmation step is set to active, it means that prep is done and the confirmation page needs to be rebuilt
    $('.adventure-container .beat-card[data-node-num].recipe .recipe-confirmation.active').each(function(){
        rebuildConfirmation($(this).closest('.recipe'), false);
    });

    function rebuildConfirmation(card, showErrors){
        var list = card.find('.recipe-confirmation').find('.recipe-list');
        list.empty();
        
        var ingredientsStep = card.find('.recipe-ingredients');

        var selections = 0;
        var minSelections = ingredientsStep.data('min-ingredients');
        var maxSelections = ingredientsStep.data('max-ingredients');

        ingredientsStep.find('input').each(function(){
            if ( $(this).attr('type') == 'checkbox' && $(this).is(':checked') ) {
                list.append($('<li>').text($(this).data('text')));
                list.append($('<input>').attr('type', 'hidden').attr('name', 'ingredients[]').val($(this).data('id')));
                selections++;
            }
            else if ( $(this).attr('type') == 'text' && $(this).val().trim() != '' ) {
                list.append($('<li>').text($(this).val()));
                list.append($('<input>').attr('type', 'hidden').attr('name', 'customIngredients[]').val($(this).val().trim()));
                selections++;
            }
        });

        if ( selections < minSelections )  {
            ingredientsStep.find('.error').text("Please select at least " + minSelections + " items.");
            return false;
        }
        else if ( selections > maxSelections ) {
            ingredientsStep.find('.error').text("Please select no more than " + maxSelections + " items.");
            return false;
        }
        return true;
    }

});