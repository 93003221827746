$(function(){

    $('.text-customizer-form').each(function(){
        var form = $(this);

        form.find('.text-customizer-form-text span[data-name]').on('click', function(){
            var name = $(this).data('name');
            var dialog = form.find('.text-customizer-form-dialog[data-name='+name+']');
            dialog.modal();
        });

        form.find('.text-customizer-form-dialog').on('hide.bs.modal', function(){
            var name = $(this).data('name');
            var value = $(this).find('textarea').val();
            form.find('.text-customizer-form-text span[data-name='+name+']').text(value);
            form.find('input[name='+name+']').val(value);
        });

    });

});