$(function(){
    // this works on all node views that have a .character-count next to an input

    $('.adventure-container .beat-card[data-node-num] .character-count').each(function(){

        var count = $(this).find('.count');
        var input = $(this).siblings('input, textarea');

        if ( input.length > 0 ) {
            var maxCharacters = input.data('max-characters');

            var onInput = function(){
                // while we're at it, prevent new lines
                input.val(input.val().replace(/\r?\n/gi, ''));

                characterCount = maxCharacters - input.val().length;
                if ( characterCount < 0 ) {
                    input.val(input.val().substr(0, maxCharacters));
                    characterCount = 0;
                }
                count.text(characterCount);
            };
            onInput();

            input.on('input', onInput);
        }


    });

});