$(function() {

    $('.adventure-container .beat-card.rating .score-button').on('click', function () {
        var score = parseInt($(this).data('score'));

        var i = 1;
        $(this).parent().find('.score-button').each(function(){
            if ( i <= score ) {
                $(this).addClass('on');
            } else {
                $(this).removeClass('on');
            }
            i++;
        });

        $(this).closest('.beat-card').find('input[name=score]').val(score);
        $(this).closest('.beat-card').find('.score-text').text(score);

    });

});
