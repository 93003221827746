$(function(){

    var map;
    var mapElement;
    var markers;
    var path;

    function initMap(location) {
        mapElement = $('<div>');
        markers = [];
        // show map
        map = new google.maps.Map(mapElement.get(0), {
            center: location,
            zoom: 16
        });
        google.maps.event.addListener(map, 'click', _.partial(onMapClick, mapElement));
    }

    function initMapWithAddress(address) {
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({'address': address}, function (results, status) {
            if ( status == google.maps.GeocoderStatus.OK ) {
                var location = results[0].geometry.location;
                initMap(location);
            }
        });
    }

    function moveMapToStep(step) {
        if ( mapElement ) {
            step.find('.gmap').empty().append(mapElement); //if there no gmap element this does nothing
        }
    }

    function createMarker(lat, lon, piece) {
        return new MarkerWithLabel({
            map: map,
            position: new google.maps.LatLng(lat, lon),
            //animation: google.maps.Animation.DROP,
            labelContent: (piece+1).toString(),
            labelAnchor: new google.maps.Point(8, 36),
            labelClass: "gmap-marker-label",
            labelInBackground: false
        });
    }

    function onMapClick(mapElement, e) {
        var step = $(mapElement).closest('.beat-card[data-node-num]').find('.step.active');

        if ( !_.isUndefined(step.data('piece')) ) {
            var piece = parseInt(step.data('piece'));
            if ( markers[piece] ) {
                var marker = markers[piece];
            } else {
                var marker = createMarker(e.latLng.lat(), e.latLng.lng(), piece);
            }
            marker.setPosition(e.latLng);
            markers[piece] = marker;

            $(mapElement).closest('.beat-card[data-node-num]').find('.hotcold-confirmation').find('input[name="pieces['+piece+'][lat]"]').val(marker.position.lat());
            $(mapElement).closest('.beat-card[data-node-num]').find('.hotcold-confirmation').find('input[name="pieces['+piece+'][lon]"]').val(marker.position.lng());

            map.panTo(e.latLng);
        }
    }


    // if we're starting on the confirmation form, generate markers from the prepopulated data
    if ( $('.hotcold-confirmation').hasClass('active') ) {
        $('.hotcold-confirmation input[data-piece]').each(function(input){
            for ( i=0; i<$('.step[data-piece]').length; i++ ) {
                var lat = $('input[data-piece='+i+'][data-field=lat]').val();
                var lon = $('input[data-piece='+i+'][data-field=lon]').val();

                if ( i==0 ) {
                    initMap(new google.maps.LatLng(lat, lon));
                }

                var marker = createMarker(lat, lon, i);
                markers[i] = marker;
            }
        });
        moveMapToStep($('.hotcold-confirmation'));
    }

    $('.adventure-container .beat-card[data-node-num].hotColdSetup .step button.detect-address').on('click', function(e){
        var step = $(this).closest('.step');
        navigator.geolocation.getCurrentPosition(
            function success(position) {
                var geocoder = new google.maps.Geocoder();
                geocoder.geocode({'latLng': new google.maps.LatLng(position.coords.latitude, position.coords.longitude)}, function(results, status){
                    if (status == google.maps.GeocoderStatus.OK) {
                        if (results[1]) {
                            step.find('input.address').val(results[1].formatted_address);
                        }
                    }
                });
            },
            function error() {
            }
        );
    });


    $('.adventure-container .beat-card[data-node-num].hotColdSetup .step .nav').on('click', 'button', function(e){
        var step = $(this).closest('.steps').find('.step').eq($(this).data('step'));
        moveMapToStep(step);
    });

    $('.adventure-container .beat-card[data-node-num].hotColdSetup .step button.prev').on('click', function(e){

        var prevStep = $(this).closest('.step').prev();
        if ( !_.isUndefined(prevStep.data('piece')) ) {
            var piece = parseInt(prevStep.data('piece'));
            var marker = markers[piece];
            map.panTo(marker.position);
        }

        // if a path is visible, kill it when going back
        if ( path ) {
            path.setMap(null);
            path = null;
        }

        moveMapToStep(prevStep);
    });

    $('.adventure-container .beat-card[data-node-num].hotColdSetup .step button.next').on('click', function(e){

        var step = $(this).closest('.step');
        var nextStep = $(this).closest('.step').next();

        if ( step.hasClass('hotcold-mapinit') && !map ) {
            // when proceding after the map init, cancel the click, wait to initialize the map, and then click
            var thisButton = $(this);
            var address = step.find('input.address').val();
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode({'address': address}, function (results, status) {
                if ( status == google.maps.GeocoderStatus.OK ) {
                    initMap(results[0].geometry.location);
                    thisButton.click(); // once location is set, trigger button again
                }
            });
            e.stopImmediatePropagation(); // prevent progress until map is set
        }

        // don't allow going past a pin step without setting a point
        if ( !_.isUndefined(step.data('piece')) ) {
            var piece = parseInt(step.data('piece'));
            if ( markers.length <= piece ) {
                e.stopImmediatePropagation();
                return;
            }
        }

        if ( !_.isUndefined(nextStep.data('piece')) ) {
            var piece = parseInt(nextStep.data('piece'));
            if ( markers.length > piece ) {
                // pan to point
                var marker = markers[piece];
                map.panTo(marker.position);
            }
        }

        // going into confirmation
        if ( nextStep.hasClass('hotcold-confirmation') ) {
            //show all points
            var pathPoints = [];
            var bounds = new google.maps.LatLngBounds();
            _(markers).each(function(marker){
                bounds.extend(marker.position);
                pathPoints.push(marker.position);
            });
            map.fitBounds(bounds);

            //draw path
            path = new google.maps.Polyline({
                path: pathPoints,
                strokeColor: '#000000',
                strokeOpacity: 1.0,
                strokeWeight: 2
            });
            path.setMap(map);
        }

        // validate location input
        var input = $(this).closest('.step').find('input');
        if ( input.length ) {
            if ( input.val().trim() == '' ) {
                e.stopImmediatePropagation();
                return;
            }

            var piece = input.data('piece');
            var confirmation = $(this).closest('.beat-card[data-node-num]').find('.scavenger-confirmation').find('li[data-piece="'+piece+'"]');
            confirmation.find('span').text(input.val());
            confirmation.find('input').val(input.val());
        }

        moveMapToStep(nextStep);
    });


});