$(function(){

    $('.adventure-container .beat-card[data-node-num].scavengerSetup .step button.next').on('click', function(e){

        //validate clue input if there is one
        var input = $(this).closest('.step').find('input');
        if ( input.length ) {
            if ( input.val().trim() == '' ) {
                e.stopImmediatePropagation();
                return;
            }

            var piece = input.data('piece');
            var confirmation = $(this).closest('.beat-card[data-node-num]').find('.scavenger-confirmation').find('li[data-piece="'+piece+'"]');
            confirmation.find('span').text(input.val());
            confirmation.find('input').val(input.val());
        }

    });

});