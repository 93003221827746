$(function(){

    // ONLY DO ANY OF THIS ON THE DASHBOARD PAGE
    if ( $('.adventure-dashboard').length == 0 ) {
        return;
    }

    var focusCard = null;

    var isLoading = false;
    var loadStartTime = 0;

    var lastScrollDelta = 0;
    var isScrolling = false;

    var unsavedChanges = false;

    // SHOW DASHBOARDS ONCE PAGE IS LOADED
    $('.adventure-container').show();

    ////////
    //////// HANDLE WINDOW SIZE
    ////////

    $(window).on('resize', function(){
        // size beats in timeline, and position active beat
        $('.adventure-container .beat-markers-container').each(function(){
            $(this).find('li').css('width', 100/($(this).find('li').length )+'%');
            positionTimelineMarker($(this).closest('.adventure-container'));
        });

        // size cards
        var timelineWidth = $('.adventure-container .beat-markers-container').width();
        $('.adventure-container .beat-cards .beat-card[data-node-num]').css('width', timelineWidth);

        // setup scrollable card area
        $('.adventure-container .beat-cards').each(function(){
            var width = 0;
            $(this).children('.beat-card[data-node-num]').each(function(){
                width += $(this).outerWidth(true);
            });
            $(this).width(width);
        });

        // size multistep cards to their final card, so that the completed mark appears in the right place
        $('.adventure-container .beat-cards .beat-card[data-node-num] .steps').each(function(){
            $(this).closest('.beat-card').css('height', $(this).find('.step').last().outerHeight());
        });

        // make sure the cards-container is tall enough to accommodate all the steps within multistep cards
        var maxHeight = 0;
        $('.adventure-container .beat-cards .beat-card[data-node-num] .steps .step').each(function(){
            maxHeight = Math.max($(this).outerHeight(), maxHeight);
        });
        $('.adventure-container .beat-cards').css('min-height', maxHeight);

        // position arrows on cards
        $('.adventure-container .beat-cards .beat-card[data-node-num]').each(function(){
            var nodeNum = $(this).data('node-num');
            var container = $(this).closest('.adventure-container');
            var marker = container.find('.beat-marker[data-node-num='+nodeNum+']');
            var arrow = $(this).find('.timeline-arrow');
            var markerContainerMargin = (container.find('.beat-markers-container').width() - container.find('.beat-markers').outerWidth()) / 2;
            arrow.css('left', Math.min(Math.max(markerContainerMargin + marker.position().left + marker.outerWidth()/2 - arrow.outerWidth()/2, 8), $(this).outerWidth()-16));
        });

        // position arrows on prep beats
        $('.adventure-container .prep-beats .prep-beats-timeline-arrow').each(function() {
            var nodeNum = $(this).data('node-num');
            var marker = $(this).closest('.adventure-container').find('.beat-marker[data-node-num='+nodeNum+']');
            $(this).css('left', Math.max(marker.position().left + marker.outerWidth()/2 - $(this).outerWidth()/2, 8));
        });

        // start on current card
        $('.adventure-container .beat-cards-scroll-container').each(function(){
            if ( focusCard ) {
                scrollToCard(focusCard, false);
            } else {
                scrollToCard($(this).find('.beat-card[data-node-num].current').get(0), false);
            }
        });
    });

    $(window).resize(); // do it once to get everything reasonably in place
    setTimeout(function(){$(window).resize()}, 1); // seems like we need to wait a frame for all the styles to load properly, and the calculations to work out perfectly

    ////////
    //////// BUILD ELEMENTS
    ////////

    $('.adventure-container').each(function(){
        refreshPrepBeats($(this));
    })

    // for multistep cards
    $('.adventure-container .beat-card[data-node-num] .steps').each(function(){
        // Inject beat-card-with-steps class into parent
        $(this).parent().addClass('beat-card-with-steps');

        // Build nav
        var nav = $('<div>');
        $(this).find('.step').each(function(i){
            if ( $(this).data('nav') ) {
                var name = $(this).data('nav');
                var button = $('<button>').text(name).attr('data-step', i);
                nav.append(button);
            }
        });

        $(this).find('.step').each(function(i){
            // clone the nav element with the appropriate element highlighted for the nav element in each step -- they're each standalone and static
            var buttonForThisStep = nav.children('[data-step='+i+']');
            if ( buttonForThisStep.length ) {
                nav.children().removeClass('active');
                buttonForThisStep.addClass('active');
            }
            $(this).find('.nav').append(nav.clone());
        });
    });





    ////////
    //////// INPUT EVENTS
    ////////

    // touch scrolling

    $('.adventure-container .beat-cards').on('touchstart', function(e){
        if ( $(e.target).is('.disable-card-scroll *') ) //don't scroll when the touch started inside a disable-card-scroll
            return;

        var self = this;
        var x = null;
        var y = null;
        var scrollDirection = null;
        $(self).on('touchmove', function(e){
            if ( x !== null && y !== null ) {
                var deltaX = e.originalEvent.changedTouches[0].screenX - x;
                var deltaY = e.originalEvent.changedTouches[0].screenY - y;
                if ( !scrollDirection ) {
                    scrollDirection = Math.abs(deltaY) > Math.abs(deltaX) ? 'ver' : 'hor';
                }
                if ( scrollDirection == 'hor' ) {
                    updateScrolling(self, deltaX);
                }
            }
            x = e.originalEvent.changedTouches[0].screenX;
            y = e.originalEvent.changedTouches[0].screenY;

            if ( Math.abs(deltaX) > Math.abs(deltaY) ) {
                return false;
            }
        });
        $(self).on('touchend', function(){
            $(self).off('touchmove');
            $(self).off('touchend');
            if ( scrollDirection == 'hor' ) {
                endScrolling(self);
            }
            x = null;
            y = null;
            scrollDirection = null;
        });
    });

    // mousewheel scrolling

    $('.adventure-container .beat-cards').on('mousewheel', function(e){
        var self = this;
        if ( e.deltaY == 0 ) {
            if ( Math.abs(e.deltaX) > 50 && !isScrolling ) {
                if ( e.deltaX > 0 ) {
                    scrollToCard($(focusCard).next(), true);
                } else {
                    scrollToCard($(focusCard).prev(), true);
                }
            }
            return false;
        }
    });

    // card navigation

    $('.adventure-container .beat-card[data-node-num]').on('click', function(){
        if ( !$(this).hasClass('focus') && !isScrolling ) {
            scrollToCard(this, true);
        }
    });
    $('.adventure-container .beat-prev').on('click', function(){
        scrollToCard($(focusCard).prev(), true);
    });
    $('.adventure-container .beat-next').on('click', function(){
        scrollToCard($(focusCard).next(), true);
    });

    // timeline navigation

    $('.adventure-container .beat-marker').on('click', function(){
        var nodeNum = $(this).data('node-num');
        card = $(this).closest('.adventure-container').find('.beat-card[data-node-num][data-node-num='+nodeNum+']');
        scrollToCard(card, true);
    });
    $('.adventure-container .active-marker').on('click', function(){
        var nodeNum = $(this).closest('.adventure-container').data('node-num');
        card = $(this).closest('.adventure-container').find('.beat-card[data-node-num][data-node-num='+nodeNum+']');
        scrollToCard(card, true);
    });

    // prep items navigation
    $('.adventure-container .prep-beats-item-link').on('click', function(){
        var nodeNum = $(this).parent().data('node-num');
        card = $(this).closest('.adventure-container').find('.beat-card[data-node-num][data-node-num='+nodeNum+']');
        scrollToCard(card, true);
        return false;
    });

    // submitting confirmation forms

    $('.adventure-container .beat-card[data-node-num] form.submit-to-caf').on('click', 'input[type=submit]', function(e){
        e.preventDefault();

        var form = $(this).closest('form');
        var data = form.serialize();
        data += '&' + $(this).attr('name') + '=' + $(this).attr('value'); //insert name/value from selected button

        submitToCAF(form.attr('action'), data, form.closest('.adventure-container'), form.closest('.beat-card[data-node-num]'));
    });

    // submitting customizable post forms

    $('.adventure-container .beat-card[data-node-num] form.submit-to-api').on('click', 'input[type=submit]', function(e){
        e.preventDefault();

        var form = $(this).closest('form');
        var container = form.closest('.adventure-container');
        var beatCard = form.closest('.beat-card[data-node-num]');

        if ( $(this).attr('name') == 'skip' ) {
            if ( beatCard.data('node-num') == container.data('node-num') ) {
                submitConfirmationForm($(this).closest('.beat-card[data-node-num]'));
            }
            else {
                showCardDone(beatCard);
            }
        }
        else {
            var data = form.serialize();
            data += '&' + $(this).attr('name') + '=' + $(this).attr('value'); //insert name/value from selected button

            var refreshOnComplete = form.hasClass('refresh-on-complete');

            submitToAPI(form.attr('action'), data, container, beatCard, refreshOnComplete);
        }
    });



    // multi step card navigation
    // note that these are bound to the parents of buttons, so module-specific code that attaches directly to these buttons can cancel navigation by stopping propagation
    $('.adventure-container .beat-card[data-node-num] .steps')
        .on('click', 'button.next', function(e){
            nextStepInCard($(this).closest('.beat-card[data-node-num]'));
        })
        .on('click', 'button.prev', function(e){
            prevStepInCard($(this).closest('.beat-card[data-node-num]'));
        })
        .on('click', '.nav button', function(e){
            selectStepInCard($(this).closest('.beat-card[data-node-num]'), $(this).data('step'));
        });

    // redo button
    $('.adventure-container .beat-card[data-node-num] .redo').on('click', function(){
        $(this).closest('.beat-card[data-node-num]').removeClass('done');
        selectStepInCard($(this).closest('.beat-card[data-node-num]'), 0);
    });

    // prep expand
    $('.adventure-container .prep-beats').on('click', function(){
        $(this).addClass('active');
    });

    // any kind of input. we'll use this to warn about unsaved change
    $('input').on('change', function(e){
        unsavedChanges = true;
    });

    $(window).on('beforeunload', function(e){
        if ( unsavedChanges ) {
            var message = "You have unsaved changes.";
            e.returnValue = message;
            return message;
        }
    });


    ////////
    //////// INTERNAL FUNCTIONS
    ////////
    
    function updateScrolling(element, delta) {
        $(element).css('left', parseInt($(element).css('left')) + delta);
        lastScrollDelta = delta;
    }

    function endScrolling(element) {
        if ( lastScrollDelta < -10 ) {
            scrollToCard($(focusCard).next(), true);
        } else if ( lastScrollDelta > 10 ) {
            scrollToCard($(focusCard).prev(), true);
        } else {
            scrollToCard($(focusCard), true);
        }
    }

    function scrollToCard(card, animated) {
        if ( !$(card).length ) {
            return;
        }

        isScrolling = true;

        if ( focusCard ) {
            $(focusCard).removeClass('focus');
        }

        var container = $(card).closest('.adventure-container');
        var pageMargin = container.find('.beat-markers-container').offset().left;
        var scrollPos = $(card).position().left - pageMargin + parseInt($(card).css('margin-left'));

        if ( animated ) {
            $(card).closest('.beat-cards').animate({left:-scrollPos}, function(){
                isScrolling = false;
            });
        } else {
            $(card).closest('.beat-cards').css({left:-scrollPos});
            isScrolling = false;
        }

        focusCard = card;
        $(focusCard).addClass('focus');

        if ( $(window).scrollTop() > container.offset().top ) {
            $('html, body').animate({scrollTop: container.offset().top});
        }
    }

    function positionTimelineMarker(adventureContainer) {
        $(adventureContainer).find('.active-marker').css('left', $(adventureContainer).find('.current').position().left);
    }

    // for submitting script actions, i.e. something that responds with updated thread info
    function submitToCAF(url, data, adventureContainer, beatCard, refreshOnComplete) {
        if ( isLoading ) {
            return;
        }
        startLoading();
        hideErrors();

        $.post(url, data)
            .always(endLoading)
            .done(function(result){
                if ( refreshOnComplete ) {
                    window.location.reload();
                }
                handleCAFCoreResponse(result, adventureContainer);
            })
            .fail(function(result){
                showErrors(beatCard, result.responseJSON.errors);
            });

        unsavedChanges = false;
    }
    window.submitToCAF = submitToCAF; //expose so inline scripts can access it

    // for submitting other kinds data, i.e. customizing things that are not specifically processed by the current node
    function submitToAPI(url, data, adventureContainer, beatCard, refreshOnComplete) {
        if ( isLoading ) {
            return;
        }
        startLoading();
        hideErrors();

        var confirmationForm = $(beatCard).find('form.confirmation-form');
        var confirmAfterSubmitting = confirmationForm.length > 0 && $(beatCard).data('node-num') == $(adventureContainer).data('node-num');
        var clearFromPrepAfterSubmitting = true;

        $.post(url, data)
            .always(endLoading)
            .done(function(){
                if ( confirmAfterSubmitting ) {
                    submitConfirmationForm(beatCard, refreshOnComplete);
                }
                else if ( refreshOnComplete ) {
                    window.location.reload();
                }
                else {
                    showCardDone(beatCard);
                }

                if ( clearFromPrepAfterSubmitting ) {
                    refreshPrepBeats(adventureContainer, $(beatCard).data('node-num'));
                }
            })
            .fail(function(result){
                showErrors(beatCard, result.responseJSON.errors);
            });

        unsavedChanges = false;
    }

    function submitConfirmationForm(beatCard, refreshOnComplete) {
        var confirmationForm = $(beatCard).find('form.confirmation-form');
        if ( confirmationForm ) {
            submitToCAF(confirmationForm.attr('action'), confirmationForm.serialize(), beatCard.closest('.adventure-container'), beatCard, refreshOnComplete);
        }
    }

    function handleCAFCoreResponse(data, adventureContainer) {
        // this is a response to a request that involved advancing the adventure, which means now we need to advance the UI

        // update data

        var prevNodeNum = $(adventureContainer).data('node-num');

        // find the new current node
        if ( data.hasOwnProperty('threads') ) {
            var curNodeNum = data.threads[0].current_node; //only one thread supported right now
        } else if ( data.hasOwnProperty('thread') ) {
            var curNodeNum = data.thread.current_node;
        }

        $(adventureContainer).data('node-num', curNodeNum);

        showCardDone($(adventureContainer).find('.beat-card[data-node-num][data-node-num='+prevNodeNum+']'));

        // scroll on after allowing time for "done" transition to play
        setTimeout(function(){

            // update classes on cards and timeline

            for ( var nodeNum=prevNodeNum; nodeNum<curNodeNum; nodeNum++ ) {
                $(adventureContainer).find('.beat-card[data-node-num][data-node-num='+nodeNum+']').removeClass('current').removeClass('future').addClass('past');
                $(adventureContainer).find('.beat-marker[data-node-num='+nodeNum+']').removeClass('current').removeClass('future').addClass('past');
            }

            var card = $(adventureContainer).find('.beat-card[data-node-num][data-node-num='+curNodeNum+']');
            card.removeClass('future').addClass('current');

            var marker = $(adventureContainer).find('.beat-marker[data-node-num='+curNodeNum+']');
            marker.removeClass('future').addClass('current');

            // move things

            scrollToCard(card, true);
            positionTimelineMarker(adventureContainer);

        }, 1000);
    }

    function showCardDone(card) {
        if ( card.hasClass('beat-card-with-steps') ) {
            // size card to current step in order for the "completed" box to appear in the right place. this size may have changed since the page was loaded, so the initial sizing on page load doesn't solve this problem.
            card.css('height', card.find('.step.active').outerHeight());
        }
        card.addClass('done').addClass('done-animated');
    }

    function showErrors(card, errors) {
        for ( var id in errors ) {
            $(card).find('.error-'+id).text(errors[id]).show();
        }
    }

    function hideErrors(card) {
        $(card).find('.error').hide();
    }

    function nextStepInCard(card){
        var steps = $(card).find('.steps .step');
        var activeStep = $(card).find('.step.active');
        var activeIndex = steps.index(activeStep);
        selectStepInCard(card, activeIndex+1);
    }

    function prevStepInCard(card){
        var steps = $(card).find('.steps .step');
        var activeStep = $(card).find('.step.active');
        var activeIndex = steps.index(activeStep);
        selectStepInCard(card, activeIndex-1);
    }

    function selectStepInCard(card, index){
        var steps = $(card).find('.steps .step');
        var activeStep = $(card).find('.step.active');
        var nextStep = steps.eq(index);

        if ( nextStep.is(activeStep) )
            return;

        if ( nextStep.parent().children().index(nextStep) > nextStep.parent().children().index(activeStep) ) {
            // forward
            TweenMax.fromTo(activeStep, 0.5, {rotation: 0}, {rotation: 60}); // spin
            TweenMax.fromTo(activeStep, 0.5, {y:0, autoAlpha:1}, {y:200, autoAlpha:0, delay:0.3}); // drop
            TweenMax.fromTo(nextStep, 0.5, {autoAlpha:0}, {autoAlpha:1});
            TweenMax.to(nextStep, 0, {rotation:0, y:0});
        } else {
            // backward
            TweenMax.fromTo(activeStep, 0.5, {autoAlpha:1}, {autoAlpha:0, delay:0.4});
            TweenMax.fromTo(nextStep, 0.5, {y:200, autoAlpha:0, rotation:45}, {y:0, autoAlpha:1, rotation:0});
        }

        activeStep.removeClass('active');
        nextStep.addClass('active');

        if ( $(window).scrollTop() > $(card).offset().top ) {
            $('html, body').animate({scrollTop: $(card).offset().top});
        }
    }

    function startLoading() {
        isLoading = true;
        loadStartTime = (new Date).getTime();

        $('#adventure-loading-modal').modal({ backdrop: false });
        $('#adventure-loading-modal').data('bs.modal').isShown = false; //prevent closing
    }

    function endLoading() {
        isLoading = false;

        var loadTime = (new Date).getTime() - loadStartTime; //ms
        if ( loadTime < 1500 ) {
            _.delay(endLoading, 1500 - loadTime);
            return;
        }

        $('#adventure-loading-modal').data('bs.modal').isShown = true; //re-enable closing
        $('#adventure-loading-modal').modal('hide');
    }

    function refreshPrepBeats(adventureContainer, removeNodeNum) {
        var prepBeats = $(adventureContainer).find('.prep-beats');
        var count = prepBeats.find('.prep-beats-item:not(.removed)').length;

        if ( removeNodeNum ) {
            var beat = prepBeats.find('.prep-beats-item[data-node-num='+removeNodeNum+']');
            if ( beat.length > 0 ) {
                beat.addClass('removed').slideUp();
                count--;
            }
        }

        if ( count > 0 ) {
            prepBeats.find('.prep-beats-title').text(count == 1 ? "There is 1 activity that requires setup." : "There are " + count + " activities that require setup.");
        }
        else {
            prepBeats.slideUp();
        }
    }

});


function setDebug(toggle){
    if ( toggle ) {
        $('.debug').show();
    }
    else {
        $('.debug').hide();
    }
}
$(function(){
    if ( window.location.search.indexOf('debug') >= 0 ) {
        setDebug(true);
    }
});