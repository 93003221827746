$(function(){

    $('.adventure-container .beat-card.personalize input[type=submit][name=submit]').on('click', function(e){

        var name = $(this).closest('form').find('input[type=text]').val().trim();

        // disallow blank
        if ( name == '' ) {
            e.stopImmediatePropagation();
            e.preventDefault();
            return;
        }

        // proper casing
        name = name.charAt(0).toUpperCase() + name.slice(1);

        // replace all instances of name on all other cards. the spans are added by ContentResolver
        $('span[data-resolved-content="player:name"]').text(name);
    });

});